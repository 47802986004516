<template>
  <el-row :gutter="20">
    <el-col :span="6">
      <el-card class="box-card">
        <span>在线</span>
        <span style="float: right; color: #67c23a;">{{ banner.online }}</span>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="box-card">
        <span>离线</span>
        <span style="float: right; color: #f56c6c;">{{ banner.offline }}</span>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="box-card">
        <span>今日营收</span>
        <span style="float: right; color: #67c23a;">￥ {{ banner.amount }}</span>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card class="box-card">
        <span>今日退款</span>
        <span style="float: right; color: #f56c6c;">￥ {{ banner.refund }}</span>
      </el-card>
    </el-col>
  </el-row>
  <el-row>
    <canvas ref="canvas" id="myChart" width="100%" height="400" />
  </el-row>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'HomePage',
  data() {
    return {
      chart: {
        ref: null,
        label: [],
        amount: [],
        refund: [],
        action: null
      },
      banner: {
        online: 0,
        offline: 0,
        amount: 0,
        refund: 0
      }

    };
  },
  created() {
    this.chart.action = this.$api.getReportCollegeOrderDay7().then(resp => {
      // resp = [{'date': '2021-02-18', 'localAmount': '0.00', 'localRefund': '0.00', 'wechatAmount': '13.00', 'wechatRefund': '0.00', 'machineOrder': 4, 'rechargeAmount': '0.00', 'totalAmount': '13.00', 'totalRefund': '0.00'}, {'date': '2021-02-19', 'localAmount': '0.00', 'localRefund': '0.00', 'wechatAmount': '9.00', 'wechatRefund': '-11.00', 'machineOrder': 3, 'rechargeAmount': '0.00', 'totalAmount': '9.00', 'totalRefund': '-11.00'}, {'date': '2021-02-20', 'localAmount': '0.00', 'localRefund': '0.00', 'wechatAmount': '7.00', 'wechatRefund': '0.00', 'machineOrder': 2, 'rechargeAmount': '0.00', 'totalAmount': '7.00', 'totalRefund': '0.00'}, {'date': '2021-02-21', 'localAmount': '0.00', 'localRefund': '0.00', 'wechatAmount': '6.00', 'wechatRefund': '0.00', 'machineOrder': 2, 'rechargeAmount': '0.00', 'totalAmount': '6.00', 'totalRefund': '0.00'}, {'date': '2021-02-22', 'localAmount': '0.00', 'localRefund': '0.00', 'wechatAmount': '0.00', 'wechatRefund': '0.00', 'machineOrder': 0, 'rechargeAmount': '0.00', 'totalAmount': '0.00', 'totalRefund': '0.00'}, {'date': '2021-02-23', 'localAmount': '0.00', 'localRefund': '0.00', 'wechatAmount': '11.00', 'wechatRefund': '0.00', 'machineOrder': 3, 'rechargeAmount': '0.00', 'totalAmount': '11.00', 'totalRefund': '0.00'}, {'date': '2021-02-24', 'localAmount': '0.00', 'localRefund': '0.00', 'wechatAmount': '9.00', 'wechatRefund': '0.00', 'machineOrder': 3, 'rechargeAmount': '0.00', 'totalAmount': '9.00', 'totalRefund': '0.00'}];
      this.chart.label = resp.map(item => item.date);
      this.chart.amount = resp.map(item => item.totalAmount);
      this.chart.refund = resp.map(item => -item.totalRefund);
      const end = resp.pop();
      this.banner.amount = end.totalAmount;
      this.banner.refund = end.totalRefund;
    });
    this.$api.getMachineReport().then(resp => {
      this.banner.online = resp.online;
      this.banner.offline = resp.all - resp.online;
    });
  },
  mounted() {
    this.chart.action.then(() => {
      this.chart.ref = new Chart(this.$refs.canvas, {
        type: 'bar',
        data: {
          labels: this.chart.label,
          datasets: [{
            label: '营收',
            data: this.chart.amount,
            borderColor: '#67c23a',
            backgroundColor: '#e1f3d8',
            // backgroundColor: '#f0f9eb',
            // yAxisID: '2',
            order: 1
          }, {
            label: '退款',
            data: this.chart.refund,
            borderColor: '#f56c6c',
            backgroundColor: '#fde2e2',
            order: 2
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                  return value + '元';
                }
              }
            }]
          }
        }
      });
    });
  }
};
</script>

<style scoped>
</style>
